<template>
    <div class="backdrop" @click.self="close">
        <div class="refund-select">
            <div class="refund-select-container" v-if="loadingData">
                <div class="refund-select-container-top">
                    <div style="display:flex;justify-content:center">
                        <div>...</div>
                    </div>
                </div>
            </div>
            <div class="refund-select-container" v-if="!loadingData">
                <div class="refund-select-container-top">
                    <div style="display:flex;justify-content:center">
                        <div>Select Items To Refund</div>
                    </div>
                    <div style="margin-bottom:10px"><hr /></div>

                    <div v-if="cartItems.length === 0">
                        <div style="display:flex;justify-content:center;color:red;margin-top: 30px;">
                            <div>No items to refund</div>
                        </div>                        
                    </div>

                    <div class="refund-select-items">
                        <div v-for="cartItem in cartItems" :key="cartItem.inventoryId">
                            <div style="display:flex;flex-direction:column;padding: 0px;margin-bottom: 20px;">
                                <!-- Item name -->
                                <div style="display:flex;flex-direction:row;justify-content:start;">
                                    <div>{{ cartItem.inventoryName }}</div>
                                </div>

                                <!-- Second Row -->
                                <div style="display:flex;flex-direction:row;justify-content:space-between;height: 25px;">
                                    <!-- Price/Qty -->
                                    <div style="display:flex;flex-direction:row;justify-content:space-between;">
                                        <!-- Max -->
                                        <div style="margin-top:auto;padding-top: 2px;margin-right: 8px;">
                                            <div>
                                                <a style="font-size: 12pt;" class="button-link" @click="cartItem.refundQuantity = cartItem.quantity" v-if="allowPartialRefund">Max</a>
                                            </div>
                                        </div>

                                        <!-- Qty -->
                                        <div style="margin-top:auto;padding-top: 2px;">
                                            <select class="" v-model="cartItem.refundQuantity" v-if="cartItem.pincode.length == 0 && allowPartialRefund">
                                                <option v-for="i in cartItem.quantity + 1" :key="i - 1" v-bind:value="i - 1">{{ i - 1 }}</option>
                                            </select>
                                            <select class="" v-model="cartItem.refundQuantity" v-if="cartItem.pincode.length > 0 && allowPartialRefund">
                                                <option value="0">0</option>
                                                <option :value="cartItem.quantity">{{ cartItem.quantity }}</option>
                                            </select>
                                            <div v-if="!allowPartialRefund">{{ cartItem.quantity }}</div>
                                        </div>

                                        <!-- Price -->
                                        <div style="margin-top:auto">&nbsp;&nbsp;x&nbsp;&nbsp;{{ Utilities.toCurrency(cartItem.price) }}</div>

                                        <!-- Sub Total -->
                                        <div style="margin-top:auto;margin-left: 20px;" v-if="cartItem.inventoryType == InventoryTypes.Inventory">
                                            <input type="checkbox" :id="'chkReturnToInventory' + cartItem.inventoryId" v-model="cartItem.refundToInventory" :disabled="cartItem.refundQuantity < 1 || cartItem.isNonInventory" />
                                            <label :for="'chkReturnToInventory' + cartItem.inventoryId">Return to inventory</label>
                                        </div>
                                    </div>

                                    <div style="margin-top:auto">{{ Utilities.toCurrency(cartItem.refundQuantity * cartItem.price) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>                

                <div style="color:grey;margin-bottom: 20px;text-align: center;" v-if="!allowPartialRefund && cartItems.length > 0">
                    Transactions with fees must be fully refunded
                </div>
                <div style="margin-bottom: 20px;" v-if="allowPartialRefund && cartItems.length > 0">&nbsp;blank</div>

                <div class="refund-totals">
                    <div class="refund-totals-line">
                        <div>Sales Total</div>
                        <div>{{ Utilities.toCurrency(salesTotal) }}</div>
                    </div>
                    <div class="refund-totals-line">
                        <div>Tax Total</div>
                        <div>{{ Utilities.toCurrency(taxTotal) }}</div>
                    </div>
                    <div class="refund-totals-line">
                        <div>Fee Total</div>
                        <div>{{ Utilities.toCurrency(feeTotal) }}</div>
                    </div>
                    <div class="refund-totals-line">
                        <div>Refund Total</div>
                        <div>{{ Utilities.toCurrency(refundTotal) }}</div>
                    </div>
                </div>

                <div>
                    <div class="refund-select-container-bottom-next">
                        <button class="refund-select-button" style="background:#EA6975" @click="close">Cancel</button>
                        <button class="refund-select-button" style="background:#8CD2A8" @click="onRefundProcess" v-if="refundTotal > 0">Next</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import apiCart from "@/api/apiCart";
import { computed, getCurrentInstance } from "@vue/runtime-core";
import Utilities from "@/common/utilities";
import Helper from "@/common/helper";
import { InventoryTypes, CartProcessingTypes, CartStatusTypes } from "@/common/enums";
import CartModel from "@/common/models/CartModel";

export default {
    setup(props, { emit }) {
        const cartItems = ref([]);
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const loadingData = ref(false);
        const feeTotal = ref(0);
        const allowPartialRefund = ref(true);

        var _cartModel = null;

        const init = (cartModel) => {
            _cartModel = cartModel;
            console.log("_cartModel", _cartModel);
            feeTotal.value = _cartModel.feeTotal;
            allowPartialRefund.value = _cartModel.feeTotal == 0;
        };

        const initWindow = async (cartModel) => {
            init(cartModel);
            try {
                loadingData.value = true;
                emitter.emit("server-call-start", "Loading order...");
                cartItems.value = await apiCart.getRefundableItems(cartModel.cartKey);
                cartItems.value.forEach((item) => {
                    item.refundQuantity = 0;
                    item.refundToInventory = !item.isNonInventory;

                    if (!allowPartialRefund.value) {
                        item.refundQuantity = item.quantity;
                    }
                });
                
                if (cartItems.value.length === 0) {
                    feeTotal.value = 0;
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading order", err]);
            }
            loadingData.value = false;
            emitter.emit("server-call-stop");
        };

        const close = () => {
            emit("close");
        };

        const onRefundProcess = () => {
            var cartModel = new CartModel();
            cartModel.cartKeySale = _cartModel.cartKey;
            cartModel.CartProcessingType = CartProcessingTypes.Refund;
            cartModel.cartStatus = CartStatusTypes.NotStarted;

            cartItems.value.forEach((item) => {
                if (item.refundQuantity > 0) {
                    var cartItem = JSON.parse(JSON.stringify(item));
                    cartItem.quantity = item.refundQuantity;
                    cartModel.cartItems.push(cartItem);
                    cartModel.itemTotal += cartItem.quantity * cartItem.price;
                    cartModel.taxTotal += cartItem.quantity * cartItem.tax * cartItem.price;
                    cartModel.grandTotal = cartModel.itemTotal + cartModel.taxTotal;
                }
            });            
            if (!allowPartialRefund.value) {
                cartModel.itemTotal = _cartModel.itemTotal.toRound(2);
                cartModel.taxTotal = _cartModel.taxTotal.toRound(2);
                cartModel.grandTotal = _cartModel.grandTotal.toRound(2);

                cartModel.feeTotal = _cartModel.feeTotal.toRound(2);
                cartModel.feeRate = _cartModel.feeRate.toRound(2);
            }

            emit("on-refund", cartModel);
        };

        const salesTotal = computed(() => {
            var total = 0;
            for (var i = 0; i < cartItems.value.length; i++) {
                total += cartItems.value[i].refundQuantity * cartItems.value[i].price;
            }
            return total.toRound(2);
        });

        const taxTotal = computed(() => {
            var total = 0;
            for (var i = 0; i < cartItems?.value.length; i++) {
                total += cartItems.value[i].refundQuantity * cartItems.value[i].price * cartItems.value[i].tax;
            }
            return total.toRound(2);
        });

        const refundTotal = computed(() => {
            var total = 0;
            for (var i = 0; i < cartItems?.value.length; i++) {
                total += cartItems.value[i].refundQuantity * cartItems.value[i].price;
                total += cartItems.value[i].refundQuantity * cartItems.value[i].price * cartItems.value[i].tax;
            }
            return total + (cartItems.value.length === 0 ? 0 : _cartModel?.feeTotal);
        });

        return {
            Utilities,
            Helper,
            close,
            cartItems,
            initWindow,
            onRefundProcess,
            salesTotal,
            taxTotal,
            feeTotal,
            refundTotal,
            InventoryTypes,
            loadingData,
            allowPartialRefund
        };
    }
};
</script>

<style scope>
.refund-select {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -400px;
    margin-left: -300px;
    width: 600px;
    height: 700px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.refund-select-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 90%;
}

.refund-select-container-top {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 70%;
}

.refund-select-container-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    height: 100%;
}

.refund-select-container-bottom-next {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    height: 100%;
}

.refund-select-button {
    background-color: #889b73;
    color: black;
    padding: 10px;
    margin-right: 10px;
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: 1px solid black;
    outline: none;
    border-radius: 10px;
    font-size: 12pt;
    font-weight: 400;
}

.refund-message {
    font-size: 16pt;
    text-align: center;
    margin-bottom: 30px;
    color: #5693f6;
}

.refund-totals {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.refund-totals-line {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.refund-select-items {
    /* background-color: var(--main-background); */
    /* align-content: flex-start; */
    /* display: flex; */
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    overflow-y: auto;
    height: 380px;
    /* margin: 10px; */
    /* border-left-style: solid;
    border-left-width: 1px; */
}
</style>
